import { mapState, mapActions } from 'vuex';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import {
	createAssetsBreadcrumb,
	updateAssetsBreadcrumb,
} from '@/helpers/listBreadcrumbs';
import {
	decimalRule,
	integerRule,
	maxValueRule,
	requiredRule,
} from '@/helpers/validationList';
import moment from 'moment';
export default {
	name: 'AssetsForm',
	data() {
		return {
			listError: [],
			valid: false,
			loadingSave: false,
			assetsForm: {
				name: '',
				area: null,
				serial_number: '',
				asset_type: null,
				num_asset: null,
				tag: null,
				observations: null,
				purchase_value: null,
				purchase_date: null,
				useful_life: null,
				maintenance_cost: null,
				other_cost: null,
				equipment_model: null,
				oc: null,
				equipment_type: null,
				monitor_model: null,
				monitor_brand: null,
				pep: null,
				operator: null,
				number_phone: null,
				imei: null,
				af: null,
				sub_number: null,
			},
			requiredRules: [(v) => !!v || 'El campo es requerido'],
			nameRules: [(v) => requiredRule(v), (v) => maxValueRule(50, v)],
			areaRules: [(v) => requiredRule(v)],
			serieRules: [(v) => requiredRule(v), (v) => maxValueRule(50, v)],
			afRules: [(v) => maxValueRule(50, v)],
			numAssetRules: [
				(v) => requiredRule(v),
				(v) => integerRule(v),
				(v) => maxValueRule(9, v),
			],
			equipmentTypeRules: [(v) => maxValueRule(50, v)],
			equipment_model: [(v) => maxValueRule(50, v)],
			tagRules: [(v) => maxValueRule(50, v)],
			ocRules: [(v) => maxValueRule(50, v)],
			purchaseValueRules: [(v) => requiredRule(v), (v) => decimalRule(v)],
			usefulLifeRules: [(v) => requiredRule(v), (v) => integerRule(v)],
			maintenanceCostRules: [(v) => decimalRule(v)],
			otherCostRules: [(v) => decimalRule(v)],
			monitorRules: [(v) => maxValueRule(50, v)],
			observationsRules: [(v) => maxValueRule(50, v)],
			subNumberRules: [(v) => maxValueRule(50, v)],
		};
	},
	computed: {
		...mapState({
			companies: (state) => state.auth.user?.companies,
			responsibles: (state) => state.security.usersActive,
		}),

		...mapState('area', ['allAreas']),
		...mapState('assetType', ['allAssetTypes']),
		...mapState('asset', ['asset']),
		sortedAllAreas() {
			return [...this.allAreas].sort((a, b) => a.name.localeCompare(b.name));
		},
		mode() {
			return this.$route.name == 'AssetsCreate' ? 'CREATE' : 'UPDATE';
		},
		breadcrumbs() {
			return this.$route.name == 'AssetsCreate'
				? createAssetsBreadcrumb
				: updateAssetsBreadcrumb;
		},
	},
	created() {
		//this.getUsersActive();
		if (this.mode === 'UPDATE') {
			this.getAsset();
		}
		this.listAllAreas();
		this.listAllAssetTypes();
	},
	methods: {
		...mapActions('asset', [
			'createAsset',
			'listAsset',
			'getAssetById',
			'updateAsset',
			'cleanAsset',
		]),
		...mapActions('security', ['getUsersActive']),
		...mapActions('area', ['listAllAreas']),
		...mapActions('assetType', ['listAllAssetTypes']),

		setValues(asset) {
			if (asset) {
				asset = { ...asset };
				const purchase_date = asset?.purchase_date
					? moment(asset?.purchase_date).format('YYYY-MM-DD')
					: '';
				this.assetsForm = {
					...this.assetsForm,
					name: asset?.name,
					area: asset?.area?.id,
					serial_number: asset?.serial_number,
					asset_type: asset?.asset_type?.id,
					af: asset?.af,
					num_asset: asset?.num_asset,
					tag: asset?.tag,
					equipment_type: asset?.equipment_type,
					equipment_model: asset?.equipment_model,
					oc: asset?.oc,
					pep: asset?.pep,
					purchase_value: asset?.purchase_value,
					useful_life: asset?.useful_life,
					maintenance_cost: asset?.maintenance_cost,
					other_cost: asset?.other_cost,
					monitor_brand: asset?.monitor_brand,
					monitor_model: asset?.monitor_model,
					observations: asset?.observations,
					purchase_date,
					operator: asset?.operator,
					number_phone: asset?.number_phone,
					imei: asset?.imei,
					subNumber: asset?.subNumber,
				};
			}
		},

		async getAsset() {
			const id = this.$route.params?.assetId;
			const { ok, response, error } = await this.getAssetById(id);
			if (ok) {
				this.setValues(response);
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
		},

		async saveAsset() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.listError = [];
			const data = {
				...this.assetsForm,
				purchase_value: parseFloat(this.assetsForm?.purchase_value),
				useful_life: parseFloat(this.assetsForm?.useful_life),
				maintenance_cost: parseFloat(this.assetsForm?.maintenance_cost),
				other_cost: parseFloat(this.assetsForm?.other_cost),
			};
			this.loadingSave = true;

			const { ok, error } = await this.createAsset(data);
			if (ok) {
				this.$router.push({ name: 'AssetsPermissions' });
			} else {
				this.listError = Array.isArray(error?.data?.message)
					? error?.data?.message
					: [error?.data?.message] || [];
			}
			this.loadingSave = false;
		},

		async assetUpdate() {
			this.listError = [];
			this.loadingSave = true;
			delete this.assetsForm.area;
			delete this.assetsForm.asset_type;

			const data = {
				...this.assetsForm,
			};

			const { ok, error } = await this.updateAsset({
				id: this.asset.id,
				data: data,
			});

			if (ok) {
				this.$router.push({
					name:
						this.$route.query.origin === 'detail'
							? 'DetailActiveInventory'
							: 'AssetsPermissions',
				});
			} else {
				this.showError(error);
			}
			this.loadingSave = false;
		},

		cancelAssetForm() {
			this.$router.push({
				name:
					this.$route.query.origin === 'detail'
						? 'DetailActiveInventory'
						: 'AssetsPermissions',
			});
		},

		buttonClickHandler() {
			if (this.$refs?.form?.validate()) {
				if (this.mode === 'CREATE') this.saveAsset();
				if (this.mode === 'UPDATE') this.assetUpdate();
			}
		},

		showError(error) {
			error = error?.data?.message;
			this.listError = Array.isArray(error) ? error : [error] || null;
		},
	},
	beforeDestroy() {
		//this.cleanAssets();
		this.cleanAsset();
	},
	components: {
		ValidationAlert,
	},
};
